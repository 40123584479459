import React from "react";
import PropTypes from "prop-types";
import AuthProvider from "./AuthProvider";
import axios from "axios";
import { decode as base64_decode, encode as base64_encode } from "base-64";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Alert, Card } from "react-bootstrap";

import "./App.css";
import LogoVertti from "./images/new_logo.png";

// eslint-disable-next-line no-restricted-globals
const params = new URLSearchParams(location.search);

const App = (props) => {
  const [auth, setAuth] = React.useState(false);

  const [demo, isDemo] = React.useState(false);

  const [isCompatible, setIsCompatible] = React.useState(true);
  const [objetoLogin, setObjetoLogin] = React.useState({});

  const [message, setMessage] = React.useState("");
  const [textoERP, setTextoERP] = React.useState("Acessar ERP Vertti");

  const browser = navigator.userAgent;
  const GG_VERSION_PERIOD = "5.0.2.29458";

  const verificaInstalacaoPlugin = async () => {
    try {
      // verifica instalação chrome
      const verificaPlugin = await axios.get(
        "chrome-extension://gjpmpkddicijikngcjlocmgojbkchkni/main.html"
      );
    } catch (e) {
      setIsCompatible(false);
      setAuth(false);
      setMessage(`Navegador Incompatível ou extensão Uniface Anywhere não instalada corretamente!
            `);
      return true;
    }
  };

  // Logado Microsoft
  React.useEffect(() => {
    if (props.account && props.account.userName) {
      const email = props.account.email
        ? props.account.email
        : props.account.userName;
      getCredenciaisUniface(email);
    }

    verificaInstalacaoPlugin();
  }, [props.account]);

  const getCredenciaisUniface = async (email_login) => {
    await verificaInstalacaoPlugin();

    try {
      // Válida e-mail CHS, retornando configurações de acesso
      const buscarCliente = await axios.post(
        "https://chsteste.vertti.com.br/valida_novo.php",
        {
          cliente: "CHST",
          email: email_login.toLowerCase(),
        }
      );

      const data = JSON.parse(
        base64_decode(base64_decode(base64_decode(buscarCliente.data)))
      );

      if (buscarCliente.status === 200 && data.success === true) {
        if (data.u) {
          setObjetoLogin(data);
          setAuth(true);
          console.log("Login OK");
        }
      } else {
        setObjetoLogin({});
        setAuth(true);
        setMessage(
          "Acesso não autorizado! Contate o administrador do sistema."
        );
        console.log("Login = Não autorizado");
      }
    } catch (e) {
      setObjetoLogin({});
      setAuth(true);
      setMessage("Acesso não autorizado! Contate o administrador do sistema.");
      console.log("Login = Não autorizado");
    }
  };

  const acessarSistema = async () => {
    if (textoERP === "aguarde, carregando sistema...") {
      return true;
    }

    console.log("Acessando sistema carregando...");
    setTextoERP("aguarde, carregando sistema...");

    const { u, h, p, ini, asn, u_vertti } = objetoLogin;

    let chromeUrl = `chrome-extension://gjpmpkddicijikngcjlocmgojbkchkni/main.html?u=${u}&h=${h}&p=${p}&ini=${ini}&asn=${asn}&u_vertti=${u_vertti}`;

    // abre anywhere modo não sign-on
    if (params.get("c") === "s") {
      chromeUrl += `&c=s`;
    }

    let iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = chromeUrl.toString();
    document.body.appendChild(iframe);

    console.log("Acessando ERP Vertti");
    setTextoERP("Acessar ERP Vertti");

    await new Promise((promisse) => setTimeout(promisse, 20000));
    iframe.parentNode.removeChild(iframe);
  };

  return (
    <div className="container">
      <div className="login">
        <Card className="text-center">
          <Card.Header>
            <div align="middle" className="logo">
              <img src={LogoVertti} align="middle" />
            </div>
          </Card.Header>
          <Card.Body>
            {!demo && message === "" && isCompatible && !props.account ? (
              <div>
                <Card.Title>
                  <h2 className="login-heading">
                    <strong>Login Vertti</strong>
                  </h2>
                  <p>(ambiente de testes)</p>
                </Card.Title>
                <p>
                  <span>código plugin: gjpmpkddicijikngcjlocmgojbkchkni</span>
                </p>
              </div>
            ) : null}

            <section>
              {props.error && <Alert variant="danger">{props.error}</Alert>}
            </section>

            <section>
              {message !== "" && (
                <div>
                  <Alert variant="danger">{message}</Alert>
                  {!isCompatible ? (
                    <p>
                      Instale a extensão no navegador{" "}
                      <a
                        target="_blank"
                        href="https://chrome.google.com/webstore/detail/vertti-anywhere/gjpmpkddicijikngcjlocmgojbkchkni?hl=pt-BR"
                      >
                        clique aqui!
                      </a>
                    </p>
                  ) : null}
                </div>
              )}
            </section>

            <section>
              {!demo && isCompatible && !props.account ? (
                <Button
                  onClick={props.onSignIn}
                  className="btn btn-dark btn-lg"
                >
                  Faça Login
                </Button>
              ) : (
                <>
                  {!demo && isCompatible ? (
                    <Button
                      type="button"
                      onClick={props.onSignOut}
                      className="btn btn-dark"
                    >
                      Sair (sign-on)
                    </Button>
                  ) : null}
                </>
              )}
            </section>

            <section>
              {demo && (
                <Alert variant="warning">Modo demonstração ativo!</Alert>
              )}
            </section>

            <section>
              {auth ? (
                <div>
                  <Card.Title>
                    <h2 className="h2-logado">
                      <strong>Bem Vindo Usuário Vertti!</strong>
                    </h2>
                  </Card.Title>
                  <p>
                    <Button
                      type="button"
                      onClick={() => acessarSistema()}
                      className="btn btn-dark btn-lg"
                    >
                      {textoERP}
                    </Button>
                  </p>
                </div>
              ) : null}
            </section>
          </Card.Body>
          <Card.Footer className="text-muted">
            <span className="footer">
              Dúvidas em como acessar o Sistema Vertti? Entre em contato com o
              suporte Vertti!
            </span>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

App.propTypes = {
  account: PropTypes.object,
  emailMessages: PropTypes.object,
  error: PropTypes.string,
  graphProfile: PropTypes.object,
  onSignIn: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onRequestEmailToken: PropTypes.func.isRequired,
};

export default AuthProvider(App);
